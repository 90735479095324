<template>
  <div class="wrap">
    <div class="x-page-title">
      <h3>合成管理</h3>
    </div>

    <div class="x-main">
      <!-- 筛选条件 -->
      <div class="x-q-panel">
        <el-row :gutter="20" style="margin-bottom: 20px">
          <el-col :span="6">
            <div class="x-con-item">
              <label>查询：</label>
              <el-input
                placeholder="请输入藏品/盲盒名称"
                v-model="query.content"
              ></el-input>
            </div>
          </el-col>

          <el-col :span="8">
            <div class="x-con-item">
              <label>创建时间：</label>
              <el-date-picker
                v-model="query.timeArr"
                type="daterange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </div>
          </el-col>

          <el-col :span="3">
            <el-button
              type="primary"
              class="x-q-btn"
              @click="
                query.pageIndex = 1;
                doQuery();
              "
              >筛选</el-button
            >
            <el-button type="text" @click="doQueryReset">重置</el-button>
          </el-col>
        </el-row>
      </div>

      <!-- 筛选结果 -->
      <div class="x-table-container">
        <div class="x-table-caption">
          <el-button
            icon="el-icon-refresh"
            type="primary"
            plain
            size="small"
            @click="doRefresh"
            >刷新</el-button
          >
          <el-button
            icon="el-icon-document"
            type="success"
            plain
            size="small"
            @click="doExport"
            >导出</el-button
          >
          <el-button
            icon="el-icon-document-copy"
            type="warning"
            plain
            size="small"
            @click="doExportAll"
            >全部导出</el-button
          >
          <el-button type="primary" size="small" class="fr" @click="goCreate"
            >添加合成藏品</el-button
          >
        </div>

        <div
          class="x-table"
          v-loading="showLoading"
          element-loading-text="数据刷新中..."
        >
          <el-table
            :data="tableData"
            stripe
            style="width: 100%"
            @selection-change="setSelection"
          >
            <el-table-column
              type="selection"
              align="center"
              width="80"
            ></el-table-column>
            <el-table-column prop="id" label="id"></el-table-column>
            <el-table-column
              prop="NFTName"
              label="藏品/盲盒名称"
            ></el-table-column>
            <el-table-column
              prop="createdAt"
              label="创建时间"
            ></el-table-column>
            <el-table-column prop="status" label="状态">
              <template slot-scope="scope">
                <span class="font-success" v-if="scope.row.status == 1"
                  >启用</span
                >
                <span class="font-normal" v-else-if="scope.row.status == 2"
                  >停用</span
                >
              </template></el-table-column
            >
            <el-table-column label="操作" align="center" width="180">
              <template slot-scope="scope">
                <div class="flex-box-around">
                  <el-link
                    :underline="false"
                    type="primary"
                    @click="goUpdate(scope.row.id)"
                    >编辑</el-link
                  >
                  <el-link
                    :underline="false"
                    type="danger"
                    @click="doDelete(scope.row.id)"
                    >删除</el-link
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>

          <div class="x-pager right">
            <el-pagination
              background
              layout="sizes, prev, pager, next"
              :current-page.sync="query.pageIndex"
              :page-size="query.pageSize"
              :page-sizes="[10, 20, 30, 50]"
              :total="total"
              @size-change="setPageSize"
              @current-change="setPageIndex"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ComposeQuery, ComposeRemove } from "@/api/module/compose";
import axios from "axios";

export default {
  name: "ComposeUpdate",
  data() {
    return {
      dialogShow: false,
      showLoading: false,
      query: {
        content: "",
        timeArr: [],
        pageIndex: 1,
        pageSize: 10,
      },
      total: 0,
      pageIndex: 1,
      pageSize: 10,
      tableData: [],
      selection: [],
    };
  },
  created() {
    this.doQuery();
  },

  methods: {
    goUpdate(id) {
      this.$router.push(`/compose/update/${id}`);
    },
    // 删除
    doDelete(id) {
      const msg = "<span class='font-danger'>确认要删除本条数据吗？</span>";
      this.$alert(msg, "删除提示", {
        dangerouslyUseHTMLString: true,
        showCancelButton: true,
        confirmButtonText: "确定删除",
        center: true,
        callback: (action) => {
          if (action === "confirm") {
            ComposeRemove(id).then((r) => {
              if (r.code === 0) {
                this.doQuery();
                this.$message({
                  type: "success",
                  message: "删除成功",
                  duration: 1300,
                });
              }
            });
          }
        },
      });
    },

    // 查询
    doQuery() {
      let query = this.setQueryParams();
      ComposeQuery(query).then((r) => {
        console.log(r);
        this.total = r.total;
        this.tableData = r.list;
      });
    },

    // 设置筛选
    setQueryParams() {
      let query = `?pagination=true&page=${this.query.pageIndex}&pageSize=${this.query.pageSize}`;
      if (this.query.content) {
        query += `&keyword=${this.query.content}`;
      }
      if (this.query.timeArr.length) {
        query += `&startTime=${this.query.timeArr[0]}&endTime=${this.query.timeArr[1]}`;
      }
      return query;
    },

    // 重置筛选
    doQueryReset() {
      this.query = {
        content: "",
        timeArr: [],
        pageIndex: 1,
        pageSize: 10,
      };
      this.doQuery();
    },

    // 刷新
    doRefresh() {
      this.showLoading = true;
      this.doQueryReset();
      setTimeout(() => {
        this.$message({
          type: "success",
          message: "数据更新完毕！",
          duration: 1300,
          onClose: () => {
            this.showLoading = false;
          },
        });
      }, 1350);
    },
    goCreate() {
      this.$router.push("/compose/create");
    },

    // 导出
    // doExport() {
    //   console.log(this.selection);
    //   if (!this.selection.length) {
    //     this.$message({
    //       type: "warning",
    //       message: "请先在表格勾选要导出的数据",
    //       duration: 1300,
    //     });
    //     return;
    //   }
    //   const ids = [];
    //   this.selection.forEach((ele) => ids.push(ele.id));
    //   let data = {
    //     ids: ids,
    //     pagination: false,
    //   };
    //   axios({
    //     method: "post",
    //     url: "admin/report/inExExport",
    //     data,
    //     responseType: "blob",
    //   })
    //     .then((res) => {
    //       let data = res.data;
    //       if (!data) {
    //         return;
    //       }
    //       let url = window.URL.createObjectURL(new Blob([data]));
    //       let a = document.createElement("a");
    //       a.style.display = "none";
    //       a.href = url;
    //       a.setAttribute("download", "excel.xls");
    //       document.body.appendChild(a);
    //       a.click(); //执行下载
    //       window.URL.revokeObjectURL(a.href);
    //       document.body.removeChild(a);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },

    // // 全部导出
    // async doExportAll() {
    //   let query = `?pagination=false`;
    //   const r = await FinanceReportQuery(query);
    //   const ids = [];
    //   r.data.list.forEach((ele) => ids.push(ele.id));
    //   let data = {
    //     ids: ids,
    //     pagination: false,
    //   };
    //   axios({
    //     method: "post",
    //     url: "admin/report/inExExport",
    //     data,
    //     responseType: "blob",
    //   })
    //     .then((res) => {
    //       let data = res.data;
    //       if (!data) {
    //         return;
    //       }
    //       let url = window.URL.createObjectURL(new Blob([data]));
    //       let a = document.createElement("a");
    //       a.style.display = "none";
    //       a.href = url;
    //       a.setAttribute("download", "excel.xls");
    //       document.body.appendChild(a);
    //       a.click(); //执行下载
    //       window.URL.revokeObjectURL(a.href);
    //       document.body.removeChild(a);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },

    // 多选
    setSelection(selection) {
      this.selection = selection;
    },

    // 设置分页
    setPageSize(pageSize) {
      this.query.pageIndex = 1;
      this.query.pageSize = pageSize;
      this.doQuery();
    },

    // 设置页码
    setPageIndex(pageIndex) {
      this.query.pageIndex = pageIndex;
      this.doQuery();
    },
  },
};
</script>